
import _ from 'lodash'
import {userRemarkList} from "@/api/custom.js"
const UserRemark = {
    state: {
        remarkList: [],

    },
    mutations: {
        SET_REMARK_LIST: (state, payload) => {
            state.remarkList = payload
        }
    },
    actions: {
        // 获取用户备注列表
        getUserRemarkList: async ({ commit },myId) => {
            try {
                const { rows } = await userRemarkList(myId)
                
                let remarksMap = {}
                if(rows&&rows.length){
                  for(let i=0;i<rows.length;i++){
                    let remark = rows[i]
                    let keyid = remark.fromHxId==myId?remark.toHxId:remark.fromHxId
                    remarksMap[keyid] = remark
                  }
                }
                console.log("%c Line:27 🧀拿到用户备注列表 remarksMap", "color:#e41a6a", remarksMap);
                if(rows){
                  commit('SET_REMARK_LIST', remarksMap)
                }else{
                  commit('SET_REMARK_LIST', {})
                }
                
            } catch (error) {
              commit('SET_REMARK_LIST', {})
            }
        }
    }
}

export default UserRemark
