import requestCustom from '@/utils/requestCustom'
// 登录客户系统
export function loginCustom(data) {
  return requestCustom({
      url: `/login`,
      method: 'post',
      data
  })
}
// 获取客户端图片验证码
export function captchaImage() {
  
  return requestCustom({
      url: `/captchaImage`,
      method: 'get'
  })
}
// 从客户系统获取个人信息
export function getUserInfo() {
  return requestCustom({
      url: `/system/user/profile`,
      method: 'get'
  })
}
// 从客户系统获取IM登录token
export function getImToken() {
  return requestCustom({
      url: `/im/msg/getUserToken`,
      method: 'get'
  })
}
// 将图片上传到自己服务器
export function uploadImg(data) {
  return requestCustom({
      url: `/upload/upHx`,
      method: 'post',
      data
  })
}
// 发送消息到客户服务器
export function sendMsg(data) {
  return requestCustom({
      url: `/im/msg/sendMsg`,
      method: 'post',
      data
  })
}

// 回收群查询群成员信息 groupId
export function getOtherGroupInfo(groupId,fromHxId) {
  return requestCustom({
      url: `/group/info/getAllNumbers?groupId=${groupId}&fromHxId=${fromHxId}`,
      method: 'get'
  })
}

// 回收群查询订单信息 groupId
export function getOrderInfo(groupId) {
  return requestCustom({
      url: `/account/krecycle/getInfoByGroupId/${groupId}`,
      method: 'get'
  })
}
// 单聊查询订单信息 groupId
export function getOrderByHxId(hxId) {
  return requestCustom({
      url: `/account/krecycle/getOrderByHxId/${hxId}`,
      method: 'get'
  })
}
// 单聊查询销售订单信息
export function getSaleOrderByHxId(hxId) {
  return requestCustom({
      url: `/need/myOrder/byHxId?HxId=${hxId}`,
      method: 'get'
  })
}

// 获取聊天快捷语
export function getQuickWords() {
  return requestCustom({
      url: `/account/reply/allList`,
      method: 'get'
  })
}
// 创建群聊
/**
 * 
 * @param {owner 
groupName
description
groupType 
other} groupId 
 * @returns 
 */
export function createPublicGroup(data) {
  return requestCustom({
      url: `/im/msg/createPublicGroup`,
      method: 'post',
      data
  })
}
// 会话内搜索聊天记录
export function searchInSession(sessionId,content='',type='text') {
  return requestCustom({
      url: `/msg/history/searchInSession?sessionId=${sessionId}&content=${content}&type=${type}`,
      method: 'get'
  })
}

// 查看上下文
// 会话历史消息记录 sessionId

export function getHistoryMessageCustom(sessionId,id='',type=1,limit=20) {
  return requestCustom({
      url: `/msg/history/recentList?sessionId=${sessionId}&type=${type}&limit=${limit}&id=${id}`,
      method: 'get'
  })
}

// 搜索会话列表记录
export function searchInAllSession(content,formUser,type='text') {
  return requestCustom({
      url: `/msg/history/searchInAllSession?content=${content}&type=${type}&formUser=${formUser}`,
      method: 'get'
  })
}
// 提交用户备注
export function saveOrUpdateOrDel(data) {
  return requestCustom({
      url: `/userRemark/saveOrUpdateOrDel`,
      method: 'post',
      data
  })
}
// 用户备注列表
export function userRemarkList(fromHxId,toHxId='') {
  return requestCustom({
      url: `/userRemark/list?fromHxId=${fromHxId}&toHxId=${toHxId}`,
      method: 'get'
  })
}

// 获取会话列表
export function sessionListList(data) {
  // ?formUser=${formUser}
  return requestCustom({
      url: `/sessionList/list?formUser=${data.formUser}&pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
      method: 'get',
      data
  })
}
// // 解散群
export function rmGroup(data) {
  return requestCustom({
      url: `/im/msg/rmGroup`,
      method: 'post',
      data
  })
}

// // 会话置顶
export function topOrCancel(data) {
  return requestCustom({
      url: `/sessionList/topOrCancel`,
      method: 'post',
      data
  })
}

// 修改群信息
export function updateGroupSettings(data) {
  return requestCustom({
      url: `/group/info/updateSettings`,
      method: 'post',
      data
  })
}

// 更新群公告
export function updateAnnouncement(data) {
  return requestCustom({
      url: `/group/info/updateAnnouncement`,
      method: 'post',
      data
  })
}

// 获取所有环信用户
export function getHaveHxUserList(keyWords,fromHxId) {
  return requestCustom({
      url: `/im/msg/getHaveHxUserList?keyWords=${keyWords}&fromHxId=${fromHxId}`,
      method: 'get'
  })
}

// 拉用户进群
export function addHxUserName(data) {
  return requestCustom({
      url: `/group/info/addHxUserName`,
      method: 'post',
      data
  })
}
// 退出群聊
export function removeHxUserName(data) {
  return requestCustom({
      url: `/group/info/removeHxUserName`,
      method: 'post',
      data
  })
}
// 设为群主
export function assignGroup(data) {
  return requestCustom({
      url: `/group/info/assignGroup`,
      method: 'post',
      data
  })
}
// 获取系统字典
export function getDict(dictType) {
  return requestCustom({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}
// 通知后台创建会话
export function sessionListAdd(data) {
  return requestCustom({
    url: '/sessionList',
    method: 'post',
    data
  })
}

// 发送短信提醒
// 通知后台创建会话
export function sendSortMsg(hxId,type) {
  return requestCustom({
    url: `/nd/login/sendDX?hxId=${hxId}&type=${type}`,
    method: 'get',
  })
}